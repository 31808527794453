import { arrayOf, bool, element } from "prop-types";

import { Container } from "react-bootstrap";
import Footer from "../generics/footer";
import NavBar from "../kabru/navBar";
import SmallNav from "./smallNav";
import SmallNavAnnouncement from "./smallNavAnnouncement";
import { useDispatch } from "react-redux";

// import dynamic from "next/dist/shared/lib/dynamic";
// const NavBar = dynamic(() => import("/components/kabru/navBar"));
// const Footer = dynamic(() => import("/components/generics/footer"));

const KabruPage = (props) => {
  KabruPage.propTypes = {
    children: arrayOf(element),
    smallNav: bool,
  };
  const dispatch = useDispatch();
  dispatch({
    type: "setDataLayer",
    content: {
      event: "pageKabruLoaded",
      current_app: "kabru",
    },
  });
  return (
    <>
      <Container fluid className={"p-0 all"} style={{ minHeight: "100vh" }}>
        <SmallNavAnnouncement />
        {props.smallNav ? <SmallNav /> : null}
        <NavBar />
        {props.children}
      </Container>
      <Footer />
    </>
  );
};

export default KabruPage;
