import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import { useEffect, useState } from "react";

import Link from "next/link";
import { useRouter } from "next/router";

const SmallNav = () => {
  const router = useRouter();
  const [current, setCurrent] = useState("particuliers");

  useEffect(() => {
    if (router.pathname.startsWith("/entreprise")) {
      setCurrent("entreprises");
    } else if (router.pathname.startsWith("/conseils")) {
      setCurrent("conseils");
    } else {
      setCurrent("particuliers");
    }
  }, [router]);

  return (
    <Row className="w-100 m-0 p-0 bg-yellow2">
      <Col xs={12} sm={12} md={12} lg={10} xl={10} className="mx-auto px-0">
        <Navbar className="py-0" bg="light">
          <Container>
            <Nav className="justify-content-center text-center d-flex mx-auto">
              <Link legacyBehavior href="/" passHref>
                <a
                  className={`my-auto text-decoration-none px-2 ${
                    current === "particuliers"
                      ? "text-primary fw-bold"
                      : "text-black"
                  }`}
                >
                  Particuliers
                </a>
              </Link>
              <Navbar.Text>&nbsp;•&nbsp;</Navbar.Text>
              <Link legacyBehavior href="/entreprises" passHref>
                <a
                  className={`my-auto text-decoration-none px-2 ${
                    current === "entreprises"
                      ? "text-primary fw-bold"
                      : "text-black"
                  }`}
                >
                  Entreprises
                </a>
              </Link>
              {/* <div className="d-none d-sm-flex">
                <Navbar.Text>&nbsp;•&nbsp;</Navbar.Text>
                <Link legacyBehavior href="/conseils" passHref>
                  <a
                    className={`my-auto text-decoration-none px-2 ${
                      current === "conseils" ? "text-primary" : "text-black"
                    }`}
                  >
                    Conseils
                  </a>
                </Link>
              </div> */}
            </Nav>
          </Container>
        </Navbar>
      </Col>
    </Row>
  );
};

export default SmallNav;
